// Polyfills
import 'Utils/_closest.polyfill.js';
import 'Utils/_matches.polyfill.js';

// Misc
// Use log() instead of console.log()
// const log = console.log.bind(console);

// Components
import { disablePageScroll, enablePageScroll, addFillGapTarget, addFillGapSelector, setFillGapMethod } from 'scroll-lock';
import Swiper, { Pagination, Navigation } from 'swiper';


document.addEventListener('DOMContentLoaded', function() {
	var r = document.querySelector(':root');
	var body = document.body;

	// HELP CHECK
	let viewportWidthEl = document.getElementById('viewport-width');

	function viewportCalc() {
		//let viewportHeight = window.innerHeight;
		let viewportWidth = window.innerWidth;
		//console.log(viewportWidth);
		//console.log(viewportHeight);

		if(window.innerWidth < 576){
			viewportWidthEl.style.backgroundColor = "red";
            viewportWidthEl.innerText = 'xs';
			//viewportHeightEl.style.backgroundColor = "red";

		} else if(window.innerWidth < 768) {
			viewportWidthEl.style.backgroundColor = "orange";
            viewportWidthEl.innerText = 'sm';

			//viewportHeightEl.style.backgroundColor = "purple";

		} else if(window.innerWidth < 992) {
			viewportWidthEl.style.backgroundColor = "green";
            viewportWidthEl.innerText = 'md';
			//viewportHeightEl.style.backgroundColor = "blue";

		} else if(window.innerWidth < 1200) {
			viewportWidthEl.style.backgroundColor = "blue";
            viewportWidthEl.innerText = 'lg';
			//viewportHeightEl.style.backgroundColor = "green";

		} else if(window.innerWidth < 1400) {
			viewportWidthEl.style.backgroundColor = "purple";
            viewportWidthEl.innerText = 'xl';
			//viewportHeightEl.style.backgroundColor = "orange";
		}

		else {
			viewportWidthEl.style.backgroundColor = "black";
            viewportWidthEl.innerText = 'xxl';
			//viewportHeightEl.style.backgroundColor = "black";
		}
	}

	viewportCalc();

	// RESIZE
	window.addEventListener("resize", function(){
		viewportCalc();
	});

	//// HEADER TINY
	var scrollPosition = window.scrollY;
	var header = document.getElementById('header');

	window.addEventListener('scroll', function() {
		scrollPosition = window.scrollY;

		if (scrollPosition >= 60) {
			header.classList.add('--tiny');
		} else {
			header.classList.remove('--tiny');
		}
	});


	//// HEADER ON SCROLL DIRECTION
	let previousScrollPosition = 0;

	const isScrollingDown = () => {
		let goingDown = false;

		let scrollPosition = window.pageYOffset;

		if (scrollPosition > previousScrollPosition) {
			goingDown = true;
		}

		previousScrollPosition = scrollPosition;

		return goingDown;
	};

	const handleScroll = () => {
		if (isScrollingDown()) {
			header.classList.add("scroll-down");
			header.classList.remove("scroll-up");
		} else {
			header.classList.add("scroll-up");
			header.classList.remove("scroll-down");
		}
	};

	function throttle(func, wait, options) {
		var timeout, context, args, result;
		var previous = 0;
		if (!options) options = {};
		
		var later = function () {
			previous = options.leading === false ? 0 : Date.now();
			timeout = null;
			result = func.apply(context, args);
			if (!timeout) context = args = null;
		};
		
		var throttled = function () {
			var _now = Date.now();
			if (!previous && options.leading === false) previous = _now;
			var remaining = wait - (_now - previous);
			context = this;
			args = arguments;
			if (remaining <= 0 || remaining > wait) {
			if (timeout) {
				clearTimeout(timeout);
				timeout = null;
			}
			previous = _now;
			result = func.apply(context, args);
			if (!timeout) context = args = null;
			} else if (!timeout && options.trailing !== false) {
			timeout = setTimeout(later, remaining);
			}
			return result;
		};
		
		throttled.cancel = function () {
			clearTimeout(timeout);
			previous = 0;
			timeout = context = args = null;
		};
		
		return throttled;
	}

	const scrollThrottle = throttle(handleScroll, 100);

	window.addEventListener("scroll", scrollThrottle, { passive: true});


	//// INTRO RIGHT MARGIN CALC
	let introContainer = document.querySelector(".--intro > .container");

	function xtremRightImgCalc() {	
		let mql = window.matchMedia('(min-width:1600px) and (max-width: 1919px)');
		
		if(introContainer) {
			if(mql.matches == true) {
				let introContainerRight = introContainer.offsetLeft;
				//console.log(introContainerRight);

				introContainer.querySelector('.img-container').style.setProperty('width', 'calc(100% + ' + introContainerRight + 'px)');
				introContainer.querySelector('.img-container').style.marginRight = "-" + introContainerRight + "px";
			} else {
				introContainer.querySelector('.img-container').style.setProperty('width', '');
				introContainer.querySelector('.img-container').style.marginRight = "";
			}
		}
    }

	xtremRightImgCalc();

	// RESIZE
	addEventListener("resize", (event) => {
		xtremRightImgCalc();
	});


	//// SERVICE LIST APPEAR
	const servicesContainer = document.querySelectorAll('.service-container');

	servicesContainer.forEach(serviceContainer => {
		serviceContainer.querySelector('.serviceBtnJS').addEventListener('click', function() {
			serviceContainer.classList.toggle('service-bottom-open');
		});
	});

	//// SWIPER SERVICES RESPONSIVE
	var initSwiperServices = false;
	let swiperServices;
	let mqlSwiper = window.matchMedia('(max-width: 991px)');

	function createSwiper() {
		if(mqlSwiper.matches == true) {
			if (!initSwiperServices) {
				initSwiperServices = true;

				swiperServices = new Swiper('.--services .swiper-container', {
					modules: [Navigation, Pagination],
					//loop: true,
					//grabCursor: true,
					//centeredSlides: true,
					spaceBetween: 30,
					//autoHeight: true,
					//freeMode: true,
					slidesPerView: 1,
					pagination: {
						el: ".--services .swiper-pagination",
					},
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					}, 
				});
			}
		} else {
			if (initSwiperServices) {
				swiperServices.destroy();
				initSwiperServices = false;

				servicesContainer.forEach(serviceContainer => {
					serviceContainer.style.opacity = "1";
				});
			}
		}
	}

	createSwiper();

	// RESIZE
	addEventListener("resize", (event) => {
		createSwiper();
	});


	//// SWIPER PROJETS
	const swiperProjets = new Swiper('.--projets .swiper-container', {
		modules: [Navigation, Pagination],
		loop: true,
		grabCursor: true,
		//centeredSlides: true,
		spaceBetween: 0,
		//freeMode: true,
		slidesPerView: 1,
        autoHeight: true,
		navigation: {
            nextEl: ".--projets .swiper-button-next",
            prevEl: ".--projets .swiper-button-prev",
        }, 
		pagination: {
			el: ".--projets .swiper-pagination",
		},
		breakpoints: {
			// when window width is >= 320px
			768: {
				slidesPerView: 3,
				spaceBetween: 20
			},
			// when window width is >= 768px
			992: {
				spaceBetween: 40,
				slidesPerView: 3,
			},
			// when window width is >= 992px
			1200: {
				slidesPerView: 3,
				spaceBetween: 80,
			}
		},
	});

	//// SWIPER REALISATIONS
	const swiperAmenagements = new Swiper('.--amenagements .swiper-container', {
		modules: [Navigation, Pagination],
		loop: true,
		grabCursor: true,
		//centeredSlides: true,
		spaceBetween: 0,
		//freeMode: true,
		slidesPerView: 1,
        autoHeight: true,
		navigation: {
            nextEl: ".--amenagements .swiper-button-next",
            prevEl: ".--amenagements .swiper-button-prev",
        }, 
		pagination: {
			el: ".--amenagements .swiper-pagination",
		},
		breakpoints: {
			// when window width is >= 320px
			768: {
			  slidesPerView: 3,
			  spaceBetween: 20
			},
			// when window width is >= 768px
			992: {
				spaceBetween: 40,
				slidesPerView: 3,
			},
			// when window width is >= 992px
			1200: {
				slidesPerView: 3,
				spaceBetween: 80,
			}
		},
	});
})

addEventListener("load", (event) => {
	//// Scroll Animation
	var sections = document.querySelectorAll('.section');

	function myFunction(x) {
		if (x.matches) { // If media query matches
			var options = {
				rootMargin: '0px',
				threshold: 0.1
			}
		
			var callback = (entries) => {
				entries.forEach((entry) => {
					var target = entry.target;
					
					if (entry.intersectionRatio >= 0.1) {
						target.classList.add("is-inview");
					} else {
						//target.classList.remove("is-inview");
					}
				})
			}
		
			var observer = new IntersectionObserver(callback, options)
			
			sections.forEach((section, index) => {
				observer.observe(section)
			})
		} else {
			var options = {
				rootMargin: '0px',
				threshold: 0.5
			}
		
			var callback = (entries) => {
				entries.forEach((entry) => {
					var target = entry.target;
					
					if (entry.intersectionRatio >= 0.5) {
						target.classList.add("is-inview");
					} else {
						//target.classList.remove("is-inview");
					}
				})
			}
		
			var observer = new IntersectionObserver(callback, options)
			
			sections.forEach((section, index) => {
				observer.observe(section)
			})
		}
	}
	  
	// Create a MediaQueryList object
	var x = window.matchMedia("(max-height: 1199px)");
	
	// Call listener function at run time
	myFunction(x);
	
	// Attach listener function on state changes
	x.addEventListener("change", function() {
		myFunction(x);
	});
});

